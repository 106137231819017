<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title d-flex">
                <h3 class="card-label">Ish xaqqi xisobotlari</h3>
              </div>

              <div class="ml-auto">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="'Excel' га чиқариш"
                  placement="top-start"
                >
                  <el-button
                    class="t-btn"
                    style="
                      min-width: 5rem;
                      height: 2.5rem;
                      background-color: #01a39b;
                      color: white;
                      display: flex;
                      align-items: center;
                    "
                    :disabled="isLoading"
                    @click="onExcelClick"
                  >
                    <!-- <svg-icon style="fill: white" icon-class="excel" /> -->
                    {{ isLoading ? 'Yuklanyapti...' : 'Excel' }}
                  </el-button>
                </el-tooltip>
              </div>
            </div>
            <div class="card-body">
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      disabled
                      dense
                      label="Sana"
                      :value="
                        filterDate(data.name.slice(0, 10)) +
                        ' ' +
                        data.name.slice(11, -1)
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      outlined
                      disabled
                      dense
                      label="Sana"
                      append-icon="event"
                      :value="data.oper_date | dateFilter"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      outlined
                      disabled
                      dense
                      append-icon="event"
                      label="Oxirgi o'zgartirilgan sana"
                      :value="data.updated_at | dateFilter"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <div v-if="isLoading" class="text-primary text-center my-5">
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  label="Large Spinner"
                ></b-spinner>
              </div>
              <div v-else class="table-scroll" id="table-scroll">
                <table id="main-table" class="main-table">
                  <thead>
                    <tr>
                      <!-- 1 -->
                      <th>#</th>
                      <!-- 2 -->
                      <th>Hodim</th>

                      <!-- 3 -->
                      <th>Tabel raqam</th>
                      <!-- 4 -->
                      <th>Lavozim</th>
                      <!-- 5 -->
                      <th>Boshlang'ich <br />qoldiq</th>
                      <!-- 6 -->
                      <th>Oklad</th>
                      <!-- 7 -->
                      <th>Premiya</th>
                      <!-- 8 -->
                      <th>
                        Tatil<br />
                        premiya
                      </th>
                      <!-- 9 -->
                      <th>Premiya <br />2</th>
                      <!-- 10 -->
                      <th>
                        Kasallik<br />
                        premiya
                      </th>
                      <!-- 11 -->
                      <th>Qo'shimcha <br />premiya-1</th>
                      <!-- 12 -->
                      <th>Qo'shimcha <br />premiya-2</th>
                      <!-- 13 -->
                      <th>
                        Jami<br />
                        hisoblangan
                      </th>
                      <!-- 14 -->
                      <th>Daromad <br />solig'i</th>
                      <!-- 15 -->
                      <th>
                        Soliqlar<br />
                        (inps)
                      </th>
                      <th>Ijtimoiy <br />tulov</th>
                      <!-- 16 -->
                      <th>Jarima</th>
                      <!-- 17 -->
                      <th>
                        Qo'shimcha<br />
                        jarima-1
                      </th>
                      <!-- 18 -->
                      <th>Qo'shimcha <br />jarima-2</th>
                      <!-- 19 -->
                      <th>Jami <br />ushlandi</th>
                      <!-- 20 -->
                      <th>Kassadan <br />to'langan</th>
                      <!-- 21 -->
                      <th>
                        Bankdan<br />
                        to'langan
                      </th>
                      <!-- 22 -->
                      <th>
                        Jami<br />
                        to'landi
                      </th>
                      <!-- 23 -->
                      <th>
                        Oxirgi<br />
                        saldo
                      </th>
                    </tr>
                  </thead>
                  <tbody class="second__tbody">
                    <tr v-for="(item, index) in list" :key="item.id">
                      <!-- 1 -->
                      <th>
                        {{ index + 1 }}
                      </th>
                      <!-- 2 -->
                      <td>
                        {{ item.staffemployed_name }}
                      </td>

                      <!-- 3 -->
                      <td>
                        {{ item.tabel_number }}
                      </td>
                      <!-- 4 -->
                      <td>
                        {{ item.postion }}
                      </td>
                      <!-- 5 -->
                      <td>
                        {{ item.start_saldo }}
                      </td>
                      <!-- 6 -->
                      <td>
                        {{ item.oklad }}
                      </td>
                      <!-- 7 -->
                      <td>
                        {{ item.premiya }}
                      </td>
                      <!-- 8 -->
                      <td>
                        {{ item.vacation_premiye }}
                      </td>
                      <!-- 9 -->
                      <td>
                        {{ item.gift_premiya }}
                      </td>
                      <!-- 10 -->
                      <td>
                        {{ item.illnes_premiya }}
                      </td>
                      <!-- 11 -->
                      <td>
                        {{ item.additional_premiya1 }}
                      </td>
                      <!-- 12 -->
                      <td>
                        {{ item.additional_premiya2 }}
                      </td>
                      <!-- 13 -->
                      <td>
                        {{ item.total_calculated }}
                      </td>
                      <!-- 14 -->
                      <td>
                        {{ item.income_tax }}
                      </td>
                      <!-- 15 -->
                      <td>
                        {{ item.inps_tax }}
                      </td>
                      <!-- 16 -->
                      <td>
                        {{ item.social_payment }}
                      </td>
                      <td>
                        {{ item.penalty }}
                      </td>
                      <!-- 17 -->
                      <td>
                        {{ item.additional_penalty1 }}
                      </td>
                      <!-- 18 -->
                      <td>
                        {{ item.additional_penalty2 }}
                      </td>
                      <!-- 19 -->
                      <td>
                        {{ item.total_subtracted }}
                      </td>
                      <!-- 20 -->
                      <td>
                        {{ item.total_payed_from_kassa }}
                      </td>
                      <!-- 21 -->
                      <td>
                        {{ item.total_payed_from_bank }}
                      </td>
                      <!-- 22 -->
                      <td>
                        {{ item.total_payed }}
                      </td>
                      <!-- 23 -->
                      <td>
                        {{ item.end_saldo }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="third__tbody">
                    <tr>
                      <!-- 1 -->
                      <th>
                        <b>
                          {{ totalSumm.staffemployed_name }}
                        </b>
                      </th>
                      <!-- 2 -->
                      <td class="second__cell"></td>
                      <!-- 3 -->
                      <td></td>
                      <!-- 4 -->
                      <td></td>
                      <!-- 5 -->
                      <td>
                        <b>
                          {{ totalSumm.start_saldo | mask }}
                        </b>
                      </td>
                      <!-- 6 -->
                      <td>
                        <b>
                          {{ totalSumm.oklad | mask }}
                        </b>
                      </td>
                      <!-- 7 -->
                      <td>
                        <b>
                          {{ totalSumm.premiya | mask }}
                        </b>
                      </td>
                      <!-- 8 -->
                      <td>
                        <b>
                          {{ totalSumm.vacation_premiye | mask }}
                        </b>
                      </td>
                      <!-- 9 -->
                      <td>
                        <b>
                          {{ totalSumm.gift_premiya | mask }}
                        </b>
                      </td>
                      <!-- 10 -->
                      <td>
                        <b>
                          {{ totalSumm.illnes_premiya | mask }}
                        </b>
                      </td>
                      <!-- 11 -->
                      <td>
                        <b>
                          {{ totalSumm.additional_premiya1 | mask }}
                        </b>
                      </td>
                      <!-- 12 -->
                      <td>
                        <b>
                          {{ totalSumm.additional_premiya2 | mask }}
                        </b>
                      </td>
                      <!-- 13 -->
                      <td>
                        <b>
                          {{ totalSumm.total_calculated | mask }}
                        </b>
                      </td>
                      <!-- 14 -->
                      <td>
                        <b>
                          {{ totalSumm.income_tax | mask }}
                        </b>
                      </td>
                      <!-- 15 -->
                      <td>
                        <b>
                          {{ totalSumm.inps_tax | mask }}
                        </b>
                      </td>
                      <!-- 16 -->
                      <td>
                        {{ totalSumm.social_payment | mask }}
                      </td>
                      <td>
                        <b>
                          {{ totalSumm.penalty | mask }}
                        </b>
                      </td>
                      <!-- 17 -->
                      <td>
                        <b>
                          {{ totalSumm.additional_penalty1 | mask }}
                        </b>
                      </td>
                      <!-- 18 -->
                      <td>
                        <b>
                          {{ totalSumm.additional_penalty2 | mask }}
                        </b>
                      </td>
                      <!-- 19 -->
                      <td>
                        <b>
                          {{ totalSumm.total_subtracted | mask }}
                        </b>
                      </td>
                      <!-- 20 -->
                      <td>
                        <b>
                          {{ totalSumm.total_payed_from_kassa | mask }}
                        </b>
                      </td>
                      <!-- 21 -->
                      <td>
                        <b>
                          {{ totalSumm.total_payed_from_bank | mask }}
                        </b>
                      </td>
                      <!-- 22 -->
                      <td>
                        <b>
                          {{ totalSumm.total_payed | mask }}
                        </b>
                      </td>
                      <!-- 23 -->
                      <td>
                        <b>
                          {{ totalSumm.end_saldo | mask }}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'

export default {
  data() {
    return {
      totalSumm: {}
    }
  },
  created() {
    this.$store.dispatch('getPayrolListDetails', this.$route.params.id)
  },
  watch: {
    list: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.totalSumm = {}
        this.totalSumm.staffemployed_name = 'Umumiy summa'
        this.totalSumm.start_saldo = 0
        this.totalSumm.social_payment = 0
        this.totalSumm.oklad = 0
        this.totalSumm.premiya = 0
        this.totalSumm.vacation_premiye = 0
        this.totalSumm.gift_premiya = 0
        this.totalSumm.additional_premiya1 = 0
        this.totalSumm.additional_premiya2 = 0
        this.totalSumm.total_calculated = 0
        this.totalSumm.income_tax = 0
        this.totalSumm.inps_tax = 0
        this.totalSumm.penalty = 0
        this.totalSumm.additional_penalty1 = 0
        this.totalSumm.additional_penalty2 = 0
        this.totalSumm.total_subtracted = 0
        this.totalSumm.total_payed_from_kassa = 0
        this.totalSumm.total_payed_from_bank = 0
        this.totalSumm.total_payed = 0
        this.totalSumm.illnes_premiya = 0
        this.totalSumm.end_saldo = 0
        val.forEach((element) => {
          this.totalSumm.start_saldo += this.toFloat(element.start_saldo)
          this.totalSumm.oklad += this.toFloat(element.oklad)
          this.totalSumm.premiya += this.toFloat(element.premiya)
          this.totalSumm.vacation_premiye += this.toFloat(
            element.vacation_premiye
          )
          this.totalSumm.gift_premiya += this.toFloat(element.gift_premiya)
          this.totalSumm.additional_premiya1 += this.toFloat(
            element.additional_premiya1
          )
          this.totalSumm.additional_premiya2 += this.toFloat(
            element.additional_premiya2
          )
          this.totalSumm.total_calculated += this.toFloat(
            element.total_calculated
          )
          this.totalSumm.income_tax += this.toFloat(element.income_tax)
          this.totalSumm.social_payment += this.toFloat(element.social_payment)
          this.totalSumm.illnes_premiya += this.toFloat(element.illnes_premiya)
          this.totalSumm.inps_tax += this.toFloat(element.inps_tax)
          this.totalSumm.penalty += this.toFloat(element.penalty)
          this.totalSumm.additional_penalty1 += this.toFloat(
            element.additional_penalty1
          )
          this.totalSumm.additional_penalty2 += this.toFloat(
            element.additional_penalty2
          )
          this.totalSumm.total_subtracted += this.toFloat(
            element.total_subtracted
          )
          this.totalSumm.total_payed_from_kassa += this.toFloat(
            element.total_payed_from_kassa
          )
          this.totalSumm.total_payed_from_bank += this.toFloat(
            element.total_payed_from_bank
          )
          this.totalSumm.total_payed += this.toFloat(element.total_payed)
          this.totalSumm.end_saldo += this.toFloat(element.end_saldo)
        })
        console.log('list', this.totalSumm)
      }
    }
  },
  filters: {
    mask(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('es-US')
      else return val
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    list() {
      const data = this.$store.state.requests.payrolListDetails
      console.log('data', data)
      return data.payrolcalcedelements_set
    },
    data() {
      const data = this.$store.state.requests.payrolListDetails
      return data
    }
  },
  methods: {
    onExcelClick() {
      const req = {
        url: `staff/salary/payrol/list/excel/${this.$route.params.id}/`,
        // url: `staff/salary/payrol/list/${this.$route.params.id}/`,
        responseType: 'blob'
      }

      axios(req)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `hisobot_kitob_qaytnomasi`)
          document.body.appendChild(link)
          link.click()
          link.outerHTML = null
          this.$store.commit('setIsLoading', false)
        })
        .catch((err) => {
          console.error(err)
          this.$store.commit('setIsLoading', false)
        })
    },
    filterDate(val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    },
    toFloat(val) {
      if (typeof val == 'string' && Boolean(val))
        return parseFloat(val.replace(/[ ,]/g, ''))
      else if (typeof val == 'number') return val
      else return 0
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Ish xaqqi xisobotlari' }
    ])
  }
}
</script>

<style scoped>
.main-table .second__tbody tr td:nth-child(2) {
  position: sticky;
  left: 123px;

  background-color: rgb(255, 255, 255);
}
.main-table .third__tbody tr .second__cell {
  position: sticky;
  left: 123px;
  background-color: rgb(255, 255, 255);
}
.w-25px {
  width: 25px;
}
.card-body-table table {
  white-space: nowrap;
}
table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tbody tr:nth-child(odd):hover {
  background-color: #f2f2f2;
}
.main-table table thead tr td {
  font-weight: bold;
}
.card-body-table table thead tr th,
.card-body-table table tbody tr th {
  text-align: center;
}
.card-body-table table tr p {
  margin-bottom: 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  height: 600px;
}
table td {
  text-align: center;
}

td,
th {
  border: 1px solid #9f9f9f;
  text-align: left;
  padding: 5px;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 600px;
}
.table-scroll table {
  width: 100%;
  white-space: nowrap;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th:first-child {
  background-color: rgb(255, 255, 255);
}
/* .table-scroll th:first-child {
} */
.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  vertical-align: middle;
}
.table-scroll thead th {
  color: #000;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
}
a:focus {
  background: red;
} /* testing links*/

th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
th:nth-child(2) {
  position: sticky;
  left: 123px;
  z-index: 2;
}

.table-scroll thead tr:nth-child(2) th {
  top: 30px;
  z-index: 2;
}

thead th:first-child,
tfoot th:first-child {
  z-index: 5;
}
.card.card-custom > .card-header:not(.flex-nowrap) {
  padding-top: 1.5rem !important;
  padding-bottom: 0 !important;
}
</style>
